import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useReactiveVar } from '@apollo/client';
import { get } from 'lodash';
import {
  authUserVar,
  isAuthVar,
  setIsAuth,
  setAuthUser,
} from '../helpers/auth';
import { handleLogout } from '../helpers/auth';
import clientStorage from '../libs/client-storage';
import { TICKETSIR_AUTH_USER_TOKEN } from '../constants';
import { parseJwt } from '../helpers/functions';
import { useIdleTimer } from 'react-idle-timer';
import apiManager from '../helpers/apiManager';

const IDLE_TIMEOUT = 60 * 60 * 1000;
const TOKEN_REFRESH_THRESHOLD = 60 * 1000;

const isJwtExpired = (expTimestamp) => {
  return Math.floor(Date.now() / 1000) >= expTimestamp;
};

const refeshJWT = async (jwt, jwtrefreshToken) => {
  try {
    const response = await apiManager.refeshJWT({
      jwt: jwt,
      refreshToken: jwtrefreshToken,
    });

    if (response?.data) {
      const accessToken = response?.data;
      const { iat, ...authUser } = parseJwt(accessToken);
      setIsAuth(accessToken);
      setAuthUser(authUser);
      apiManager.setToken(accessToken);
    }
  } catch (error) {
    handleLogout();
    toast.info('Your session has expired. Please login again.', {
      position: 'top-center',
      toastId: 'session-expired',
    });
  }
};

const useTokenExpiry = () => {
  const authUser = useReactiveVar(authUserVar);
  const isAuth = useReactiveVar(isAuthVar);
  const refreshTimeoutRef = useRef(null);

  const handleIdle = () => {
    handleLogout();
    if (refreshTimeoutRef.current) clearTimeout(refreshTimeoutRef.current);
    toast.info('Your session has expired. Please login again.', {
      position: 'top-center',
      toastId: 'session-expired',
    });
  };

  useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: handleIdle,
    debounce: 500,
    disabled: !isAuth,
  });

  useEffect(() => {
    if (!isAuth) {
      if (refreshTimeoutRef.current) clearTimeout(refreshTimeoutRef.current);
      return;
    }
    const exp = get(authUser, 'exp');
    const checkTokenExpiry = () => {
      if (
        !!clientStorage.getItem(TICKETSIR_AUTH_USER_TOKEN) &&
        isJwtExpired(exp)
      ) {
        if (refreshTimeoutRef.current) clearTimeout(refreshTimeoutRef.current); // Clear any existing timeout
        handleLogout();
        toast.info('Your session has expired. Please login again.', {
          position: 'top-center',
          toastId: 'session-expired',
        });
      }
    };

    const currentTimeSeconds = Math.floor(Date.now() / 1000);
    const timeUntilExpiry = (exp - currentTimeSeconds) * 1000;

    if (timeUntilExpiry > TOKEN_REFRESH_THRESHOLD) {
      if (refreshTimeoutRef.current) clearTimeout(refreshTimeoutRef.current);
      refreshTimeoutRef.current = setTimeout(
        () =>
          refeshJWT(
            clientStorage.getItem(TICKETSIR_AUTH_USER_TOKEN),
            authUser.refreshToken
          ),
        timeUntilExpiry - TOKEN_REFRESH_THRESHOLD
      );

      return () => {
        if (refreshTimeoutRef.current) clearTimeout(refreshTimeoutRef.current);
      };
    } else {
      checkTokenExpiry();
    }
  }, [authUser, isAuth]);

  return { authUser }; // Return the current token and authUser if needed
};

export default useTokenExpiry;
