import * as React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import usePreventBodyScroll from '../../hooks/use-prevent-scroll';

function PhotoLightbox({
  photos,
  initialPhotoIndex,
  isLightboxOpen,
  onCloseComplete,
}) {
  const [photoIndex, setPhotoIndex] = React.useState(initialPhotoIndex);
  // usePreventBodyScroll(isLightboxOpen);

  React.useEffect(() => {
    setPhotoIndex(initialPhotoIndex);
  }, [initialPhotoIndex]);

  if (isLightboxOpen) {
    const hasMultiplePhotos = photos.length > 1;

    return (
      <Lightbox
        mainSrc={photos[photoIndex]}
        nextSrc={
          hasMultiplePhotos ? photos[(photoIndex + 1) % photos.length] : null
        }
        prevSrc={
          hasMultiplePhotos
            ? photos[(photoIndex + photos.length - 1) % photos.length]
            : null
        }
        onCloseRequest={onCloseComplete}
        onMovePrevRequest={
          hasMultiplePhotos
            ? () =>
                setPhotoIndex(
                  (prev) => (prev + photos.length - 1) % photos.length
                )
            : undefined
        }
        onMoveNextRequest={
          hasMultiplePhotos
            ? () => setPhotoIndex((prev) => (prev + 1) % photos.length)
            : undefined
        }
      />
    );
  }

  return null;
}

export default PhotoLightbox;
