import React from 'react';
import { Dialog, Paragraph, Pane, Heading, majorScale } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import { convertTextToHyphenated } from '../../helpers/functions';

const PresaleLiveModel = ({
  preSaleStarted,
  eventReferenceNumber,
  EventTitle,
}) => {
  const [isShown, setIsShown] = React.useState(false);
  React.useEffect(() => {
    if (preSaleStarted) {
      setIsShown(preSaleStarted);
    }
  }, [preSaleStarted]);
  return (
    <Dialog
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      hasFooter={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      hasHeader={false}
    >
      <Pane>
        <Pane display="flex" justifyContent="flex-end">
          <Paragraph onClick={() => setIsShown(false)} cursor="pointer">
            X
          </Paragraph>
        </Pane>

        <Pane marginBottom={majorScale(2)} textAlign="center">
          <Heading fontSize="1.5em">Get yout tickets early</Heading>
        </Pane>
        <Paragraph
          fontSize="1em"
          textAlign="center"
          marginBottom={majorScale(2)}
        >
          Presale tickets are out now!{' '}
          <Link
            to={`/events/${eventReferenceNumber}/${convertTextToHyphenated(
              EventTitle
            )}/register-interest`}
          >
            Sign up
          </Link>{' '}
          to the waitlist to access presale tickets now!
        </Paragraph>
      </Pane>
    </Dialog>
  );
};
export default PresaleLiveModel;
