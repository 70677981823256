import React from 'react';
import ReactDOM from 'react-dom';

import Bugsnag from '@bugsnag/js';
import { QueryClientProvider } from 'react-query';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import HttpsRedirect from 'react-https-redirect';
import { ToastContainer } from 'react-toastify';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import App from './app/app';
import clientStorage from './libs/client-storage';
import queryClient from './libs/react-query-client';
import ApolloClientProvider from './libs/apollo-client';
import UserIpAddressProvider from './providers/user-ipaddress';
import CustomEvergreenTheme from './libs/custom-evergreen-theme';
import MaintenancProtectedRoute from './routes/maintenanc-protected/maintenanc-protected';

import GlobalStyles from './components/global-styles/global-styles';
import ErrorStateMsg from './components/error-state-msg/error-state-msg';

import './helpers/i18n';
import 'react-datepicker/dist/react-datepicker.css';
import './assets/css/custom-datepicker.css';
import './assets/css/stripe-card-form.css';
import './helpers/i18n';
import { CookieProvider } from './providers/cookie-policy';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={ErrorStateMsg}
      onError={(event) => {
        const error = event.originalError;

        if (error instanceof Error) {
          const chunkFailedMessage = /Loading chunk [\d]+ failed/;

          if (error?.message && chunkFailedMessage.test(error.message)) {
            if (!clientStorage.getWithExpiry('chunk_failed')) {
              clientStorage.setWithExpiry('chunk_failed', 'true', 10000);
              window.location.reload();
            }
          }
        }
      }}
    >
      <ApolloClientProvider>
        <MaintenancProtectedRoute
          component={
            <QueryClientProvider client={queryClient}>
              <UserIpAddressProvider>
                <CustomEvergreenTheme>
                  <GlobalStyles />
                  <ToastContainer />
                  <Elements stripe={stripePromise}>
                    <GoogleOAuthProvider
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    >
                      <CookieProvider>
                        <HttpsRedirect>
                          <App />
                        </HttpsRedirect>
                      </CookieProvider>
                    </GoogleOAuthProvider>
                  </Elements>
                </CustomEvergreenTheme>
              </UserIpAddressProvider>
            </QueryClientProvider>
          }
        />
      </ApolloClientProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
