import { useEffect } from 'react';

const usePreventBodyScroll = (isActive) => {
  useEffect(() => {
    const body = document.body;
    let scrollY = 0;

    if (isActive) {
      // Save the current scroll position
      scrollY = window.scrollY;
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}px`;
      body.style.width = '100%';
    } else {
      // Restore the scroll position
      scrollY = parseInt(body.style.top || '0', 10) * -1;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, scrollY);
    }

    return () => {
      // Cleanup: Reset styles and scroll position on unmount
      const scrollY = parseInt(body.style.top || '0', 10) * -1;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, scrollY);
    };
  }, [isActive]);
};

export default usePreventBodyScroll;
